import React from "react";
import { Link } from "gatsby";
import Layout from "../components/shared/Layout";
import { StaticImage } from "gatsby-plugin-image";
import "../assets/styles/pages/404.scss";

const NotFound = () => (
  <Layout>
    <div className={"wrapper"}>
      <div className={"columns"}>
        <div className={"col-left"}>
          <StaticImage
            src={"../assets/images/not-found-error.png"}
            alt="404 Not found"
            placeholder="blurred"
            width={612}
            quality={100}
          />
        </div>
        <div className={"col-right"}>
          <h1>เราไม่พบหน้าเว็บที่ค้นหา</h1>
          <p>คุณอาจเข้าลิงก์ผิด หรือใส่ที่อยู่ไม่ถูกต้อง</p>
          <p>แต่เราอาจช่วยคุณได้</p>
          <p>
            <Link to={"/"} className={"button"}>คลิกที่ลิงก์นี้เพื่อกลับไปสู่หน้าหลัก</Link>
          </p>
        </div>
      </div>


    </div>
  </Layout>
);

export default NotFound;
